<template>
 <div :class="windowClass">
        <v-row class="pa-2">
          <v-col cols="8">
              <v-row>
                <v-col cols="6">
                  <v-card class="pa-5 text-center" color="accent" dark>
                      
                        <div class="text-h4"><v-icon large left>mdi-database-import</v-icon>{{$money(totalpoints)}}</div>
                        Points loaded as of {{$moment().format("MMM. DD, YYYY")}}
                  </v-card>
                  </v-col>
                  <v-col cols="6">
                      <v-card class="pa-5 text-center" color="info" dark>
                          <div class="text-h4"><v-icon large left>mdi-database-export</v-icon>{{$money(totalcashout)}}</div>
                          Points cashout as of {{$moment().format("MMM. DD, YYYY")}}
                      </v-card>
                  </v-col>

                  <v-col cols="6">
                  <v-card class="pa-5 text-center" color="accent" dark>
                      
                        <div class="text-h4"><v-icon large left>mdi-sack-percent</v-icon>{{$money(totalcommision)}}</div>
                        Commissions as of {{$moment().format("MMM. DD, YYYY")}}
                  </v-card>
                  </v-col>
                  <v-col cols="6">
                      <v-card class="pa-5 text-center" color="info" dark>
                          <div class="text-h4"><v-icon large left>mdi-database</v-icon>{{$money(totalconverted)}}</div>
                          Convert to  points as of {{$moment().format("MMM. DD, YYYY")}}
                      </v-card>
                  </v-col>
              </v-row>

          </v-col>
              <v-col cols="4" class="mt-3">
                <v-card class="pt-10 pb-10 text-center" color="success" dark>
                  <div class="pt-10  pb-8">
                    <div class="text-h2"><v-icon x-large left>mdi-database-sync</v-icon>{{$money(totalpoints-totalcashout)}}</div>
                    Moving Points as of {{$moment().format("MMM. DD, YYYY")}}
                    </div>
                </v-card>
            </v-col>
            <v-col cols="6">
              <report-cash :show="true" />
            </v-col>
            <v-col cols="6">
              <report-cash-out :show="true" />
            </v-col>
        </v-row>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      report_type:'Overall',
      areas:[],
      totalpoints:0,
      totalcashout:0,
      totalcommision:0,
      totalconverted:0,
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getCashFlow()
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      getCashFlow(){
           this.$http.get("report/cash_flow").then(response => {
             if(response.data.status){
                this.totalcashout = response.data.cashout
                 this.totalpoints = response.data.load
                  this.totalcommision = response.data.commision
                 this.totalconverted = response.data.converted

             }
         }).catch(e => {
           console.log(e.data)
         })
      }
    }
  }
</script>